<template>
  <lf-modal
    :close="() => emit('close')"
    :title="$t('EMAIL_INTEGRATION.ADD_NEW_ALIAS')"
    small
  >
    <template #content>
      <loader :is-loading="loading" />
      <div class="divide-y">
        <div class="p-6 space-y-2">
          <span class="text-label">
            {{ $t("COMMON.CHOOSE") }} {{ $t("COMMON.CONTACTS") }}
          </span>
          <search-input
            v-model.trim="searchText"
            no-margin
            no-padding
            data-cy="search-input"
          />
          <div class="max-h-60 space-y-1 overflow-y-auto px-2">
            <lf-checkbox
              v-for="user in filteredUsers"
              :key="user.id"
              v-model="selectedUsersIds"
              :value="user.id"
              :name="user.id.toString()"
              class="w-fit-content"
            >
              <div class="flex flex-col space-y-1">
                <span class="text-headline font-semibold">
                  {{ getFullName(user) }}
                </span>
                <span class="text-label">{{ user.email }}</span>
              </div>
            </lf-checkbox>
          </div>
        </div>
        <modal-buttons
          class="justify-end py-5"
          :on-cancel="() => emit('close')"
          :on-save="handleAliasesUpdate"
          :save-button-text="$t('COMMON.ADD')"
          :disabled="!selectedUsersIds.length"
        />
      </div>
    </template>
  </lf-modal>
</template>

<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";
import SearchInput from "@/components/ui/inputs/SearchInput.vue";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import ModalButtons from "@/components/ui/ModalButtons.vue";
import Loader from "@/components/ui/Loader.vue";

import { computed, ref } from "vue";
import { usePromiseWrapper } from "@/hooks/common";
import clientsApiService from "@/services/modules/clients";
import { useClients } from "@/hooks/clients";
import cloneDeep from "lodash/cloneDeep";

import type { IUser } from "@/models/users";
import type { IWhitelabel } from "@/models/clients";
import myApiService from "@/services/modules/my";

const props = defineProps<{
  whitelabel: IWhitelabel;
  selectedUsers: number[];
}>();

const emit = defineEmits<{
  close: [];
  "alias:create": [];
}>();

const { activeClient } = useClients();

const aliasUsers = ref<IUser[]>([]);
const selectedUsersIds = ref<number[]>(cloneDeep(props.selectedUsers));
const searchText = ref("");

const filteredUsers = computed<IUser[]>(() => {
  if (!searchText.value) {
    return aliasUsers.value;
  }

  return aliasUsers.value.filter(({ last_name, first_name, email }) =>
    `${last_name}${first_name}${email}`
      .toLowerCase()
      .includes(searchText.value.toLowerCase())
  );
});

const getFullName = (user: IUser) => {
  return [user.first_name, user.last_name].filter(Boolean).join(" ");
};

const { fetchWrapper: handleAliasesUpdate } = usePromiseWrapper(async () => {
  if (!activeClient.value || !props.whitelabel.id) {
    return;
  }
  await clientsApiService.storeWhitelabelAliases({
    clientId: activeClient.value.id,
    whitelabelId: props.whitelabel.id,
    userIds: selectedUsersIds.value.filter(
      (id) => !props.selectedUsers.includes(id)
    )
  });
  emit("close");
  emit("alias:create");
});

const { fetchWrapper: getAllAliases, loading } = usePromiseWrapper(async () => {
  if (!activeClient.value || !props.whitelabel.id) {
    return;
  }
  aliasUsers.value = await myApiService.getOrganizationUsers();
});

getAllAliases();
</script>
