import type { LogEndpointType } from "@/enums/communicationLogs";
import type { IDealsColumn } from "@/models/applications";
import type { IInscribeReport } from "@/models/commercialData/Inscribe";
import type { CommunicationLogBase } from "@/models/communicationLogs";
import type { ComputedRef, InjectionKey, Ref } from "vue";

export const ScorecardsIsViewKey: InjectionKey<boolean> = Symbol(
  "ScorecardsIsViewKey"
);

export const ScorecardsIsWorkflowBuilder: InjectionKey<boolean> = Symbol(
  "ScorecardsIsWorkflowBuilder"
);

export const ScorecardsIsBacktestReport: InjectionKey<boolean> = Symbol(
  "ScorecardsIsBacktestReport"
);

export const AllBopOpenKey: InjectionKey<Ref<boolean>> = Symbol("allBopOpen");

export const AllDnbKybOpenKey: InjectionKey<Ref<boolean>> =
  Symbol("allDnbKybOpen");

export const AllOcrolusV2OpenKey: InjectionKey<Ref<boolean>> =
  Symbol("allOcrolusV2Open");

export const AllOcrolusCffOpenKey: InjectionKey<Ref<boolean>> =
  Symbol("allOcrolusCffOpen");

export const AllOcrolusEtOpenKey: InjectionKey<Ref<boolean>> =
  Symbol("allOcrolusEtOpen");

export const serviceName: InjectionKey<string> = Symbol("providerName");

export const serviceType: InjectionKey<string> = Symbol("providerType");

export const DealsColumnsKey: InjectionKey<ComputedRef<IDealsColumn[]>> =
  Symbol("dealsColumns");

export const ProfileViewActiveTab: InjectionKey<Ref<string>> =
  Symbol("activeTab");

export const InscribeReportKey: InjectionKey<IInscribeReport | undefined> =
  Symbol("inscribeReport");

export const CommunicationLogContextKey: InjectionKey<{
  type: LogEndpointType | null;
  logId: CommunicationLogBase["id"] | null;
}> = Symbol("communicationLogContextKey");

export const OrchestrationServicesSearchKey: InjectionKey<Ref<string>> = Symbol(
  "orchestrationServicesKey"
);
