<template>
  <div class="border rounded-4 p-4 space-y-1">
    <div class="flex items-center justify-between">
      <div class="flex space-x-2 items-center">
        <span class="text-label flex items-center space-x-1">
          <span>@</span>
          <router-link
            v-if="getWhiteLabelRoute(whitelabel.id)"
            :to="getWhiteLabelRoute(whitelabel.id)"
          >
            <lf-h4>{{ whitelabel.domain_name }}</lf-h4>
          </router-link>
          <lf-h4 v-else>{{ whitelabel.domain_name }}</lf-h4>
        </span>
        <total-indicator
          v-tooltip="$t('COMMON.ALIAS', whitelabel.aliases_count)"
          :value="whitelabel.aliases_count"
        />
        <icon-base
          v-if="whitelabel.is_primary"
          :icon="IconPrimaryIndicator"
          class="text-blue-500"
        />
        <icon-base v-if="isSuccessStatus" :icon="IconSuccessBigGreen" />
        <lf-pill v-else-if="isPendingStatus" class="uppercase" :color-index="5">
          {{ whitelabel.domain_identity_status }}
        </lf-pill>
        <icon-base
          v-else-if="whitelabel.domain_identity_status"
          class="rounded-full"
          :icon="IconFailBigRed"
        />
      </div>
      <action-menu-wrapper
        v-if="whitelabel.id"
        :items="menuItems"
        :offset="offset"
        teleport
      />
    </div>
    <span v-if="whitelabel.updated_at" class="text-label">
      {{
        $t("EMAIL_INTEGRATION.LAST_UPDATED", {
          date: formatDateCustom(
            whitelabel.updated_at,
            "MM.dd.yyyy hh:mm a",
            true
          )
        })
      }}
    </span>
    <confirm-modal
      v-if="showDeleteModal"
      :title="`${$t('COMMON.DELETE')} ${$t('COMMON.DOMAIN')}`"
      :description="
        $t('EMAIL_INTEGRATION.DELETE_ITEM_PROMPT', {
          item: whitelabel.domain_name || $t('COMMON.DOMAIN')
        })
      "
      :confirm-button="$t('COMMON.DELETE')"
      :close="() => (showDeleteModal = false)"
      confirm-button-type="error"
      @confirm="deleteWhitelabel"
    />
  </div>
</template>
<script setup lang="ts">
import TotalIndicator from "@/components/deals/dataServices/cfa/moneythumb/TotalIndicator.vue";
import IconFailBigRed from "@/components/icons/IconFailBigRed.vue";
import IconPrimaryIndicator from "@/components/icons/IconPrimaryIndicator.vue";
import IconSuccessBigGreen from "@/components/icons/IconSuccessBigGreen.vue";
import IconBase from "@/components/ui/IconBase.vue";
import LfPill from "@/components/ui/text/LfPill.vue";
import ActionMenuWrapper, {
  type MenuItem
} from "@/components/ui/ActionMenuWrapperV2.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import type { IClient, IWhitelabel } from "@/models/clients";

import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { formatDateCustom } from "@/helpers/formatting";
import { usePromiseWrapper } from "@/hooks/common";
import clientsApiService from "@/services/modules/clients";
import { useClients } from "@/hooks/clients";
import { useStore } from "vuex";
import { useNotification } from "@/hooks/notifications";

const props = defineProps<{ whitelabel: IWhitelabel }>();

const emit = defineEmits<{
  "whitelabel:delete": [];
}>();

const offset = { left: -58, top: 43 };

const { t } = useI18n();
const { activeClient } = useClients();
const { dispatch, getters } = useStore();
const { showMessage } = useNotification();

const showDeleteModal = ref(false);

const isSuccessStatus = computed(
  () =>
    props.whitelabel.domain_identity_status?.toLowerCase() ===
    t("COMMON.SUCCESS").toLowerCase()
);
const isPendingStatus = computed(
  () =>
    props.whitelabel.domain_identity_status?.toLowerCase() ===
    t("COMMON.PENDING").toLowerCase()
);

const menuItems = computed<Array<MenuItem>>(() => [
  {
    label: t("COMMON.DELETE"),
    handler: () => (showDeleteModal.value = true)
  },
  {
    label: t("COMMON.REFRESH"),
    handler: async () => refreshStatus()
  }
]);

const authClientId = computed(
  () => (getters["auth/authClientSettings"] as IClient)["id"]
);

const { fetchWrapper: refreshStatus } = usePromiseWrapper(async () => {
  if (!props.whitelabel.id) {
    return;
  }
  await dispatch("clients/getDomainStatus", props.whitelabel.id);
  showMessage(t("COMMON.STATUS_REFRESHED"), "success");
});

const { fetchWrapper: deleteWhitelabel } = usePromiseWrapper(async () => {
  if (props.whitelabel.id && activeClient.value) {
    await clientsApiService.deleteDns(
      activeClient.value?.id,
      props.whitelabel.id
    );
    showDeleteModal.value = false;
    emit("whitelabel:delete");
  }
});

const getWhiteLabelRoute = (id: number) => {
  if (authClientId.value && id)
    return {
      name: "WhitelabelView",
      params: { clientId: authClientId.value, whitelabelId: id }
    };
  return "";
};
</script>
