<template>
  <lf-modal :title="modalTitle" :close="close">
    <template #content>
      <form @submit.prevent="onSubmit">
        <div class="flex-col p-6">
          <lf-input
            placeholder="Full name"
            :value="model.full_name || ''"
            name="full_name"
          />
          <lf-input
            placeholder="Email"
            :value="model.email || ''"
            name="email"
          />
        </div>
        <div class="border-t border-gray-200 p-6 flex justify-end">
          <outline-button @click="close" class="mr-3">
            {{ $t("COMMON.CANCEL") }}
          </outline-button>
          <primary-button type="submit" :disabled="isSubmitting">
            {{ $t("COMMON.SAVE") }}
          </primary-button>
        </div>
      </form>
    </template>
  </lf-modal>
</template>

<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";

import { useForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { callStoreAction } from "@/helpers/vee-validate";
import { useUsersStore } from "@/stores/users";
import type { PropType } from "vue";

const { t } = useI18n();
const usersStore = useUsersStore();

const props = defineProps({
  model: {
    type: Object,
    default: () => ({
      full_name: "",
      email: "",
      id: ""
    })
  },
  close: {
    type: Function as PropType<() => void>,
    required: true
  }
});

const isEditMode = !!props.model.id;
const modalTitle = isEditMode
  ? `${t("COMMON.EDIT")} ${props.model.full_name}`
  : t("ORGANIZATION.ADD_SALES_REP");

const { handleSubmit, isSubmitting } = useForm({
  initialValues: { ...props.model }
});

const onSubmit = handleSubmit(async (values, actions) => {
  try {
    await callStoreAction(
      {
        data: {
          ...props.model,
          ...values
        }
      },
      actions,
      isEditMode ? "updateSalesRepresentative" : "createSalesRepresentative",
      usersStore
    );
  } catch {
    return;
  }

  props.close();
});
</script>
