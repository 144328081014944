<template>
  <div
    class="flex flex-col lg:flex-row justify-end items-baseline"
    :class="{
      'mb-6': !EXCLUDE_ORG_TITLE_TABS.includes(activeTab)
    }"
  >
    <div
      v-if="$t('ORGANIZATION.SALES_REPRESENTATIVES') === activeTab"
      class="user-controls-wrapper"
    >
      <search-input v-model="search" class="-ml-2 lg:-ml-0 mr-2 -mb-0" />
      <access-control :roles="[SUPER_ADMIN, ADMIN, CLIENT_ADMIN]">
        <primary-button
          class="h-full"
          @click.prevent="showSalesRepModal = true"
        >
          {{ $t("ORGANIZATION.ADD_SALES_REP") }}
        </primary-button>
      </access-control>
    </div>
  </div>
  <div>
    <component
      :is="organizationTabs[activeTab]"
      v-if="showComponent"
      :search="search"
      :client-id="activeClient?.id"
      :client-notifications="activeClient?.notifications || []"
      :company-name="activeClient?.name"
      :user-type="UserType.client"
      :class="{
        'max-w-screen-md': activeTab === $t('NOTIFICATIONS.TITLE')
      }"
    />
    <loader v-else is-loading />
  </div>
  <sales-rep-modal
    v-if="showSalesRepModal"
    :close="() => (showSalesRepModal = false)"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useAuth } from "@/hooks/auth";
import { ref, onUnmounted, computed, inject, onBeforeMount } from "vue";
import type { IClient } from "@/models/clients";
import { SUPER_ADMIN, ADMIN, CLIENT_ADMIN } from "@/helpers/constants";
import { ProfileViewActiveTab } from "@/helpers/symbols";
import { usePermissions } from "@/hooks/auth";

import Commissions from "@/views/profile/components/Commissions.vue";
import ApiConfig from "@/views/profile/components/ApiConfig.vue";
import BusinessCreditData from "@/views/profile/components/BusinessCreditData.vue";
import BYOC from "@/views/profile/components/Byoc.vue";
import Inquiries from "@/components/profile/Inquiries.vue";
import NotificationsSettings from "@/views/profile/components/notifications/NotificationsSettings.vue";
import CloseApi from "@/views/profile/components/CloseApi.vue";
import PasswordSettings from "@/views/profile/components/PasswordSettings.vue";
import Underwriting from "@/views/profile/components/Underwriting.vue";
import SalesRepresentative from "@/views/profile/components/SalesRepresentatives.vue";
import SalesRepModal from "@/views/profile/modals/SalesRepModal.vue";
import EmailIntegration from "@/views/profile/emailIntegration/EmailIntegrationWhitelabels.vue";
import CountriesView from "@/views/profile/CountriesView.vue";
import WebhookNotificationsViewVue from "@/views/profile/WebhookNotificationsView.vue";
import DataOrchestrationToggle from "@/views/profile/components/DataOrchestrationToggle.vue";
import TestMode from "@/views/profile/components/TestMode.vue";
import WorkflowBuilderToggle from "@/views/profile/components/WorkflowBuilderToggle.vue";
import BorrowerPlatformToggle from "@/views/profile/components/BorrowerPlatformToggle.vue";
import ScorecardsToggle from "@/views/profile/components/ScorecardsToggle.vue";
import AttributeBuilderSettings from "@/views/profile/components/AttributeBuilderSettings.vue";
import SSNMask from "@/views/profile/components/SSNMask.vue";
import PresetsList from "@/components/presets/PresetsList.vue";
import EmployeesList from "@/views/profile/components/UsersList.vue";
import EmailTemplatesClientToggle from "@/components/emailTemplates/EmailTemplatesClientToggle.vue";
import ContractBuilderClientToggle from "@/components/contractBuilder/ContractBuilderClientToggle.vue";
import LenderNetworkSettings from "@/views/profile/components/LenderNetworkSettings.vue";

import { Ability, PermissionSubject, UserType } from "@/enums/auth";

const { canPerformActionReactive, EXCLUDE_ORG_TITLE_TABS } = usePermissions();
const route = useRoute();
const store = useStore();
const { t } = useI18n();
const auth = useAuth();

const showSalesRepModal = ref(false);
const search = ref("");
const activeClient = computed<IClient | null>(
  () => store.getters["clients/active"]
);

const organizationTabs = computed(() => ({
  [t("ORGANIZATION.USER_MANAGEMENT")]: EmployeesList,
  [t("ORGANIZATION.COMMISSIONS.TITLE")]: Commissions,
  [t("ORGANIZATION.BUSINESS_CREDIT_DATA.TITLE")]: BusinessCreditData,
  [t("ORGANIZATION.BYOC.TITLE")]: BYOC,
  [t("ORGANIZATION.UNDERWRITING")]: !!activeClient.value?.id && Underwriting,
  [t("ORGANIZATION.DATA_ORCHESTRATION")]:
    auth.isAdmin && !!activeClient.value?.id && DataOrchestrationToggle,
  [t("COMMON.WORKFLOW_BUILDER")]:
    (auth.isAdmin || auth.isSuperAdmin) && WorkflowBuilderToggle,
  [t("SCORECARDS.TITLE")]:
    (auth.isAdmin || auth.isSuperAdmin) && ScorecardsToggle,
  [t("ORGANIZATION.API_CONFIG.TITLE")]: ApiConfig,
  [t("ORGANIZATION.PASSWORD_SETTINGS.TAB_TITLE")]: PasswordSettings,
  [t("NOTIFICATIONS.EMAIL_NOTIFICATIONS")]:
    !auth.isClient && NotificationsSettings,
  [t("NOTIFICATIONS.WEBHOOK_NOTIFICATIONS")]:
    !auth.isClient && WebhookNotificationsViewVue,
  [t("ORGANIZATION.CLOSE_API.TAB_NAME")]: !auth.isClient && CloseApi,
  [t("ORGANIZATION.SALES_REPRESENTATIVES")]:
    !!activeClient.value?.equipment_rental_enabled && SalesRepresentative,
  [t("EMAIL_INTEGRATION.TITLE")]:
    (auth.isAdmin || auth.isEmployee) && EmailIntegration,
  [t("COMMON.COUNTRIES")]:
    (auth.isAdmin || auth.isSuperAdmin || auth.isUnderwriter) && CountriesView,
  [t("ORGANIZATION.TEST_MODE.TITLE")]:
    (auth.isAdmin || auth.isSuperAdmin) && TestMode,
  [t("ORGANIZATION.INQUIRIES.TITLE")]: Inquiries,
  [t("ATTRIBUTE_BUILDER.ATTRIBUTE_BUILDER")]:
    auth.isLendflowUser && AttributeBuilderSettings,
  [t("ORGANIZATION.SSN_MASK.TITLE")]:
    (auth.isAdmin || auth.isSuperAdmin || auth.isClientAdmin) && SSNMask,
  [t("PRESETS.TITLE")]: canViewPresets.value && PresetsList,
  [t("ORGANIZATION.EMAIL_TEMPLATES.TITLE", { count: 2 })]:
    (auth.isAdmin || auth.isClientAdmin) && EmailTemplatesClientToggle,
  [t("CONTRACTS.CONTRACT_BUILDER.CONTRACT_BUILDER")]:
    (auth.isAdmin || auth.isClientAdmin) && ContractBuilderClientToggle,
  [t("DEALS.APPLICATION_LINKS.BORROWER_PLATFORM")]:
    !!activeClient.value?.id && BorrowerPlatformToggle,
  [t("LENDER_NETWORK.TITLE")]: LenderNetworkSettings
}));

const activeTab = inject(
  ProfileViewActiveTab,
  ref(t("ORGANIZATION.USER_MANAGEMENT"))
);

const canViewPresets = canPerformActionReactive(
  PermissionSubject.permissionsPreset,
  Ability.viewAny,
  {}
);

const authClientSettings = computed<IClient>(
  () => store.getters["auth/authClientSettings"]
);

const showComponent = computed(() => {
  const isEmailNotifications =
    activeTab.value === t("NOTIFICATIONS.EMAIL_NOTIFICATIONS");

  const canShowEmailNotifications =
    activeClient.value?.id &&
    activeClient.value?.name &&
    activeClient.value?.notifications;

  return (
    !isEmailNotifications || (isEmailNotifications && canShowEmailNotifications)
  );
});

onBeforeMount(() => {
  store.dispatch(
    "clients/find",
    auth.isClient ? authClientSettings.value.id : route.params.id
  );
});

onUnmounted(() => {
  store.commit("clients/unsetActive");
});
</script>

<style scoped>
.user-controls-wrapper {
  @apply flex flex-col md:flex-row mt-4 lg:mt-0 justify-center w-full lg:w-max;
}
</style>
