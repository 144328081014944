import { defineStore } from "pinia";
import vuexStore from "@/store";
import usersApiService from "@/services/modules/users";

import type { IUsersState } from "@/models/users";
import type { IResponseLinks, IResponseMeta } from "@/models/common";
import type { IUser } from "@/models/users";
import type { IPaginatedResponse } from "@/models/common";
import { produce } from "immer";
import type { IClient } from "@/models/clients";

const getDefaultState = (): IUsersState => {
  return {
    usersData: {
      data: [],
      links: {} as IResponseLinks,
      meta: {} as IResponseMeta,
      loading: false
    }
  };
};

export const useUsersStore = defineStore("users", {
  state: () => getDefaultState(),
  actions: {
    setUsers(usersPayload: IPaginatedResponse<IUser>) {
      this.usersData = {
        ...this.usersData,
        ...usersPayload
      };
    },

    unsetUsers() {
      this.usersData = {
        data: [],
        links: {} as IResponseLinks,
        meta: {} as IResponseMeta,
        loading: false
      };
    },

    setCreatedUser(createdUser: IUser) {
      this.usersData.data = [createdUser, ...this.usersData.data];
      if (typeof this.usersData?.meta?.total === "number") {
        this.usersData.meta.total += 1;
      }
    },

    setUpdatedUser(updatedUser: IUser) {
      this.usersData.data = produce(this.usersData.data, (draft) => {
        const index = draft.findIndex((user) => user.id === updatedUser.id);
        draft[index] = { ...draft[index], ...updatedUser };
      });
    },

    unsetDeletedUser(deletedUserId: number) {
      this.usersData.data = this.usersData.data.filter(
        (user) => user.id !== deletedUserId
      );
    },

    async getUsers({
      clientId,
      params
    }: {
      clientId: string;
      params: Record<string, unknown>;
    }) {
      try {
        this.usersData.loading = true;
        const response = await usersApiService.getClientUsers(clientId, params);
        this.setUsers(response);
      } finally {
        this.usersData.loading = false;
      }
    },

    async getEmployees({ params }: { params: Record<string, unknown> }) {
      try {
        this.usersData.loading = true;
        const response = await usersApiService.getEmployees(params);
        this.setUsers(response);
      } finally {
        this.usersData.loading = false;
      }
    },

    async create(payload: Partial<IUser> & { appendCreated: boolean }) {
      const createdUser = await usersApiService.createUser(payload);
      if (payload.client_id || payload.appendCreated) {
        this.setCreatedUser(createdUser);
      }
      void vuexStore?.dispatch("clients/refreshActiveClient", null, {
        root: true
      });
      return createdUser;
    },

    async updateUser({
      user,
      userId
    }: {
      user: Partial<IUser>;
      userId: number;
    }) {
      const updatedUser = await usersApiService.updateUser(user, userId);
      this.setUpdatedUser(updatedUser);
      void vuexStore?.dispatch("clients/refreshActiveClient", null, {
        root: true
      });
    },

    async deleteUser({ userId }: { userId: IUser["id"] }) {
      const { status } = await usersApiService.deleteUser(userId);
      if (status === 204) {
        this.unsetDeletedUser(userId);
      }
      void vuexStore?.dispatch("clients/refreshActiveClient", null, {
        root: true
      });
      return status === 204;
    },

    async getSalesRepresentatives({
      clientId,
      params
    }: {
      clientId: string;
      params: Record<string, unknown>;
    }) {
      try {
        this.usersData.loading = true;
        const representatives =
          await usersApiService.getClientSalesRepresentatives(clientId, params);
        this.setUsers(representatives);
      } finally {
        this.usersData.loading = false;
      }
    },

    async createSalesRepresentative({
      clientId,
      data
    }: {
      clientId: string;
      data: Partial<IUser>;
    }) {
      clientId =
        clientId || (vuexStore?.state.clients.active?.id as IClient["id"]);

      if (!clientId) {
        return;
      }

      const createdRep = await usersApiService.createSalesRepresentative(
        clientId,
        data
      );
      if (createdRep.id) {
        this.setCreatedUser(createdRep);
      }
      return createdRep;
    },

    async updateSalesRepresentative({
      clientId,
      data
    }: {
      clientId: string;
      data: IUser;
    }) {
      clientId =
        clientId || (vuexStore?.state.clients.active?.id as IClient["id"]);

      if (!clientId) {
        return;
      }

      const updatedRep = await usersApiService.updateSalesRepresentative(
        clientId,
        data
      );

      if (updatedRep.id) {
        this.setUpdatedUser(updatedRep);
      }

      return updatedRep;
    },

    async deleteSalesRepresentative({
      clientId,
      representativeId
    }: {
      clientId?: string;
      representativeId: number;
    }) {
      try {
        this.usersData.loading = true;
        clientId =
          clientId || (vuexStore?.state.clients.active?.id as IClient["id"]);

        if (!clientId) {
          return;
        }

        const { status } = await usersApiService.deleteSalesRepresentative(
          clientId,
          representativeId
        );
        if (status === 204) {
          this.unsetDeletedUser(representativeId);
        }
      } finally {
        this.usersData.loading = false;
      }
    },

    async setUserNotifications({
      userId,
      data,
      auth = false
    }: {
      userId: number;
      data: { notifications: Record<string, number[]> };
      auth?: boolean;
    }) {
      const response = await usersApiService.setUserNotifications(userId, data);
      if (auth) {
        void vuexStore?.dispatch("auth/getAuthDetails", null, { root: true });
      }

      return response;
    },

    async updateClientOwner({
      data,
      id
    }: {
      data: Partial<IUser>;
      id: number;
    }) {
      const updatedOwner = await usersApiService.updateUser(data, id);
      vuexStore?.commit("clients/setUpdatedClientOwner", updatedOwner, {
        root: true
      });
    }
  }
});
