<template>
  <div class="grid grid-cols-11 items-center" data-cy="email-alias">
    <span class="col-span-4 text-headline break-words">
      {{ userAlias }}
    </span>
    <div class="flex items-center space-x-2 col-span-6">
      <avatar-with-name :user="alias.user" />
      <span class="text-label text-xs">
        {{ alias.user.email }}
      </span>
    </div>

    <action-menu-wrapper
      class="justify-self-end"
      :items="menuItems"
      :offset
      teleport
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { IWhitelabel, WhitelabelAlias } from "@/models/clients";
import ActionMenuWrapper, {
  type MenuItem
} from "@/components/ui/ActionMenuWrapperV2.vue";
import { useI18n } from "vue-i18n";
import AvatarWithName from "@/components/ui/AvatarWithName.vue";

const props = defineProps<{
  alias: WhitelabelAlias;
  whitelabel: IWhitelabel;
}>();

const emit = defineEmits<{
  "alias:delete": [];
}>();

const offset = { left: -58, top: 43 };

const { t } = useI18n();

const userAlias = computed(() => {
  const userName = [props.alias.user.first_name, props.alias.user.last_name]
    .filter(Boolean)
    .map((item) => item.toLowerCase())
    .join(".");
  return `${userName}@${props.whitelabel.domain_name}`;
});

const menuItems = computed<Array<MenuItem>>(() => [
  {
    label: t("COMMON.DELETE"),
    handler: () => emit("alias:delete")
  }
]);
</script>
