<template>
  <form class="space-y-6" @submit="submit">
    <loader :is-loading="isSubmitting" />
    <!-- header -->
    <div class="flex justify-between items-center">
      <lf-h4>{{ $t("COMMON.DOMAIN") }} {{ $t("COMMON.INFO") }}</lf-h4>
      <span v-if="whitelabel.updated_at" class="text-label">
        {{
          $t("EMAIL_INTEGRATION.LAST_UPDATED", {
            date: formatDateCustom(
              whitelabel.updated_at,
              "MM.dd.yyyy hh:mm a",
              true
            )
          })
        }}
      </span>
    </div>
    <!-- domain fields -->
    <div class="grid grid-cols-2 gap-6">
      <lf-input
        v-for="field in domainFields"
        :key="field.name"
        :placeholder="field.label"
        :data-cy="field.name"
        :value="field.value"
        :name="field.name"
        type="text"
        class="flex-1"
      >
        <template #suffix>
          <icon-base
            v-if="
              field.name === 'domain_name' &&
              whitelabel?.domain_identity_status?.toLowerCase() ===
                t('COMMON.SUCCESS').toLowerCase()
            "
            :icon="IconSuccessBigGreen"
          />
        </template>
      </lf-input>
    </div>
    <!-- primary toggle -->
    <div
      class="border rounded-4 p-6 flex items-center justify-between gap-4 flex-wrap"
    >
      <lf-switch
        v-model="initialValues.is_primary"
        name="is_primary"
        class="flex-none w-fit-content"
      >
        <lf-h4 class="mr-4">
          {{ $t("CUSTOMIZE.SET_AS_PRIMARY") }}
        </lf-h4>
      </lf-switch>
      <lf-switch
        v-model="initialValues.use_in_communication_emails"
        name="use_in_communication_emails"
        data-cy="use-in-communication-emails"
        class="flex-none w-fit-content"
      >
        <lf-h4 class="mr-4">
          {{ useInCommunicationEmailsLabel }}
        </lf-h4>
      </lf-switch>
    </div>
    <!-- aliases assignment -->
    <div class="border rounded-4 divide-y">
      <div class="py-5 px-6 flex flex-col space-y-2">
        <div class="flex items-center space-x-4">
          <lf-h4>{{ $t("EMAIL_INTEGRATION.ALIASES_ASSIGNMENT") }}</lf-h4>
          <total-indicator :value="savedAliases.length" />
        </div>
        <span>{{ $t("EMAIL_INTEGRATION.ASSOCIATE_USERS_ACCOUNTS") }}</span>
      </div>
      <div class="grid grid-cols-11 items-center px-6 py-2">
        <span class="col-span-4 text-label text-xs">
          {{ $t("EMAIL_INTEGRATION.ALIAS") }}
        </span>
        <span class="col-span-6 text-label text-xs">
          {{ $t("COMMON.USER") }}
        </span>
        <button
          v-tooltip="
            whitelabel.id
              ? $t('EMAIL_INTEGRATION.ADD_ALIAS')
              : $t('EMAIL_INTEGRATION.CAN_NOT_ADD_ALIAS_MESSAGE')
          "
          type="button"
          data-cy="add-alias-button"
          class="p-2 rounded-4 w-fit-content justify-self-end"
          :class="[
            whitelabel.id ? 'hover:bg-grey-hover' : 'cursor-not-allowed'
          ]"
          @click="handleAddAliasClick"
        >
          <icon-base :icon="IconAdd" :width="20" :height="20" />
        </button>
      </div>
      <loader v-if="aliasesLoading" :is-loading="aliasesLoading" />
      <div
        v-else-if="savedAliases.length"
        class="py-6 px-6 max-h-80 overflow-y-auto"
      >
        <whitelabel-alias-block
          v-for="alias in savedAliases"
          :key="alias.user.id"
          :alias="alias"
          :whitelabel="whitelabel"
          @alias:delete="handleDeleteAlias(alias)"
        />
      </div>
      <div v-else class="py-12 flex flex-col items-center">
        <lf-h4>{{ $t("EMAIL_INTEGRATION.NO_ALIAS_SET_MESSAGE_1") }}</lf-h4>
        <span class="text-label">
          {{ $t("EMAIL_INTEGRATION.NO_ALIAS_SET_MESSAGE_2") }}
        </span>
      </div>
    </div>
    <!-- action buttons -->
    <div class="border-t -mx-6 p-6 flex justify-end space-x-4">
      <outline-button @click.prevent="emit('whitelabel:cancel')">
        {{ $t("COMMON.CANCEL") }}
      </outline-button>
      <button
        type="submit"
        data-cy="save-whitelabel-data-button"
        class="text-blue-600 bg-blue-100 hover:bg-blue-200 active:bg-blue-300 px-6 py-2 rounded shadow-sm font-semibold"
      >
        {{ $t("COMMON.SAVE") }}
      </button>
    </div>
  </form>
  <add-aliases-modal
    v-if="showAddAliasesModal && whitelabel.id"
    :whitelabel="whitelabel"
    :selected-users="savedAliases.map(({ user }) => user.id)"
    @close="showAddAliasesModal = false"
    @alias:create="emit('alias:create', whitelabel.id)"
  />
  <confirm-modal
    v-if="showDeleteModal && activeAlias"
    :title="`${$t('COMMON.DELETE')} ${$t('COMMON.ALIAS')}`"
    :description="
      $t('EMAIL_INTEGRATION.DELETE_ITEM_PROMPT', {
        item: activeAlias.user.email
      })
    "
    :confirm-button="$t('COMMON.DELETE')"
    :close="closeDeleteAliasModal"
    confirm-button-type="error"
    @confirm="deleteAlias"
  />
</template>
<script setup lang="ts">
import LfH4 from "@/components/ui/text/LfHeading4.vue";
import LfInput from "@/components/ui/inputs/LfInput.vue";
import IconSuccessBigGreen from "@/components/icons/IconSuccessBigGreen.vue";
import LfSwitch from "@/components/ui/inputs/LfSwitch.vue";
import TotalIndicator from "@/components/deals/dataServices/cfa/moneythumb/TotalIndicator.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconAdd from "@/components/icons/deals/IconAdd.vue";
import Loader from "@/components/ui/Loader.vue";
import AddAliasesModal from "@/views/profile/emailIntegration/AddAliasesModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import OutlineButton from "@/components/ui/buttons/OutlineButton.vue";
import WhitelabelAliasBlock from "@/views/profile/emailIntegration/WhitelabelAlias.vue";

import { filterObjectByValue, formatDateCustom } from "@/helpers/formatting";
import { useI18n } from "vue-i18n";
import { ref, type PropType, watch } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { useForm } from "vee-validate";

import type { IWhitelabel, WhitelabelAlias } from "@/models/clients";
import clientsApiService from "@/services/modules/clients";
import { usePromiseWrapper } from "@/hooks/common";
import { useClients } from "@/hooks/clients";
import { computed } from "vue";
import { dispatchAction } from "@/helpers/vee-validate";

type DomainField = {
  label: string;
  value: string | undefined;
  name: keyof IWhitelabel;
};

const props = defineProps({
  whitelabel: {
    type: Object as PropType<IWhitelabel>,
    required: true
  },
  savedAliases: {
    type: Array as PropType<WhitelabelAlias[]>,
    required: true
  },
  loading: {
    type: Boolean
  }
});

const emit = defineEmits<{
  "whitelabel:cancel": [];
  "alias:delete": [IWhitelabel["id"]];
  "alias:create": [IWhitelabel["id"]];
  "whitelabel:created": [];
  "whitelabel:edited": [];
}>();

const { t } = useI18n();

const initialValues = ref(props.whitelabel);
const { handleSubmit } = useForm({ initialValues });
const { activeClient } = useClients();

const domainFields = ref<DomainField[]>([]);
const activeAlias = ref<WhitelabelAlias | null>(null);

const showAddAliasesModal = ref(false);
const showDeleteModal = ref(false);

const aliasesLoading = computed(
  () => deleteAliasLoading.value || props.loading
);

const useInCommunicationEmailsLabel = computed(() =>
  initialValues.value.use_in_communication_emails
    ? t("EMAIL_INTEGRATION.USE_IN_COMMUNICATION_EMAILS")
    : t("EMAIL_INTEGRATION.USE_IN_EMAILS_PRODUCT")
);

const handleDeleteAlias = (alias: WhitelabelAlias) => {
  activeAlias.value = alias;
  showDeleteModal.value = true;
};

const getInputValues = (): DomainField[] => [
  {
    label: t("COMMON.DOMAIN"),
    value: props.whitelabel.domain_name,
    name: "domain_name"
  },
  {
    label: t("EMAIL_INTEGRATION.FROM_EMAIL"),
    value: props.whitelabel.from_email,
    name: "from_email"
  },
  {
    label: t("EMAIL_INTEGRATION.REPLY_TO"),
    value: props.whitelabel.reply_to_email,
    name: "reply_to_email"
  },
  {
    label: t("EMAIL_INTEGRATION.FROM_PERSON"),
    value: props.whitelabel.from_person,
    name: "from_person"
  }
];

const onSubmit = handleSubmit(async (values, actions) => {
  const whitelabelId = props.whitelabel?.id;
  const actionName = whitelabelId ? "updateWhiteLabel" : "addWhiteLabel";
  const filteredPayload = filterObjectByValue(
    values as Record<string, unknown>,
    (val) => !!val
  );
  const payload = {
    ...filteredPayload,
    use_in_communication_emails: values.use_in_communication_emails ?? false,
    is_primary: values.is_primary,
    id: whitelabelId
  };
  await dispatchAction(payload, actions, `clients/${actionName}`);
  whitelabelId ? emit("whitelabel:edited") : emit("whitelabel:created");
});

const closeDeleteAliasModal = () => {
  showDeleteModal.value = false;
  activeAlias.value = null;
};

const handleAddAliasClick = () => {
  if (!props.whitelabel.id) {
    return;
  }
  showAddAliasesModal.value = true;
};

const { fetchWrapper: submit, loading: isSubmitting } =
  usePromiseWrapper(onSubmit);

const { fetchWrapper: deleteAlias, loading: deleteAliasLoading } =
  usePromiseWrapper(async () => {
    if (!activeClient.value || !activeAlias.value) {
      return;
    }
    await clientsApiService.deleteWhitelabelAlias({
      clientId: activeClient.value.id,
      whitelabelId: activeAlias.value.whitelabel_id,
      aliasId: activeAlias.value.id
    });
    activeAlias.value = null;
    emit("alias:delete", props.whitelabel.id);
  });

watch(
  () => props.whitelabel,
  async () => {
    domainFields.value = getInputValues();
    initialValues.value = cloneDeep(props.whitelabel);
  },
  { immediate: true }
);
</script>
