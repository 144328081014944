<template>
  <div class="bg-white rounded shadow-sm py-6">
    <loader :is-loading="fetchingWhitelabelsLoading" />
    <div class="grid grid-cols-3 divide-x">
      <!-- Select Domain -->
      <div class="px-6 space-y-8 flex flex-col">
        <div class="flex items-center justify-between">
          <div class="flex space-x-2">
            <lf-h4>{{ $t("COMMON.DOMAIN", 2) }}</lf-h4>
            <total-indicator :value="whitelabelsWithBlueprint?.length ?? 0" />
          </div>
          <button
            v-tooltip="$t('EMAIL_INTEGRATION.ADD_DOMAIN')"
            class="hover:bg-grey-hover p-2 rounded-4"
            data-cy="add-whitelabel-button"
            :disabled="
              !!(
                whitelabelsWithBlueprint?.length &&
                !whitelabelsWithBlueprint?.[0]?.id
              )
            "
            @click="addWhitelabelBlueprint"
          >
            <icon-base :icon="IconAdd" :width="20" :height="20" />
          </button>
        </div>
        <div
          v-if="whitelabelsWithBlueprint?.length"
          class="space-y-3 flex flex-col max-h-[80vh] overflow-auto"
        >
          <whitelabel-card
            v-for="whitelabel in whitelabelsWithBlueprint"
            :key="whitelabel.id"
            :whitelabel
            class="hover:bg-blue-50 cursor-pointer"
            :class="{
              'bg-blue-100 border-blue-500':
                activeWhitelabel?.id === whitelabel.id
            }"
            @click="getActiveWhitelabelAliases(whitelabel)"
            @whitelabel:delete="handleWhitelabelDelete"
          />
        </div>
        <div
          v-else
          class="text-grey-500 flex-1 justify-center items-center flex flex-col space-y-1"
        >
          <lf-h4>{{ $t("EMAIL_INTEGRATION.NO_DOMAINS_SET_MESSAGE_1") }}</lf-h4>
          <span class="text-label text-center">
            {{ $t("EMAIL_INTEGRATION.NO_DOMAINS_SET_MESSAGE_2") }}
          </span>
        </div>
      </div>
      <!-- Selected Whitelabel Details -->
      <div class="col-span-2 px-6 min-h-100">
        <whitelabel-details
          v-if="activeWhitelabel"
          :whitelabel="activeWhitelabel"
          :saved-aliases="activeWhitelabelAliases"
          :loading="fetchAliasesLoading"
          @whitelabel:cancel="removeWhitelabelBlueprint"
          @alias:delete="handleAliasAction"
          @alias:create="handleAliasAction"
          @whitelabel:created="getWhitelabels(false)"
          @whitelabel:edited="getWhitelabels"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import LfH4 from "@/components/ui/text/LfHeading4.vue";
import TotalIndicator from "@/components/deals/dataServices/cfa/moneythumb/TotalIndicator.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconAdd from "@/components/icons/deals/IconAdd.vue";
import WhitelabelCard from "@/views/profile/emailIntegration/WhitelabelCard.vue";
import WhitelabelDetails from "@/views/profile/emailIntegration/WhitelabelDetails.vue";
import Loader from "@/components/ui/Loader.vue";

import { computed, ref } from "vue";
import { useStore } from "vuex";

import type { IWhitelabel, WhitelabelAlias } from "@/models/clients";
import { usePromiseWrapper } from "@/hooks/common";
import { useClients } from "@/hooks/clients";
import clientsApiService from "@/services/modules/clients";
import { watch } from "vue";

const { getters, dispatch } = useStore();
const { activeClient } = useClients();

const whitelabelBlueprint: IWhitelabel = {
  id: 0,
  domain_name: "",
  domain_identity_status: "",
  from_email: "",
  from_person: "",
  reply_to_email: "",
  created_at: "",
  updated_at: "",
  is_primary: false,
  aliases_count: 0,
  use_in_communication_emails: false
};

const storedWhitelabels = computed<IWhitelabel[] | null>(
  () => getters["clients/whitelabels"]
);

const whitelabelsWithBlueprint = ref<IWhitelabel[] | null>(
  storedWhitelabels.value
);
const activeWhitelabel = ref<IWhitelabel | null>(
  whitelabelsWithBlueprint.value?.[0] ?? null
);

const whitelabelsAliases = ref<Record<number, WhitelabelAlias[]>>({});
const activeWhitelabelAliases = ref<WhitelabelAlias[]>([]);

const addWhitelabelBlueprint = () => {
  whitelabelsWithBlueprint.value?.unshift(whitelabelBlueprint);
  activeWhitelabel.value = whitelabelBlueprint;
};

const getActiveWhitelabelAliases = async (whitelabel: IWhitelabel) => {
  activeWhitelabel.value = whitelabel;

  if (!whitelabel.id) {
    return;
  }

  if (whitelabelsAliases.value[whitelabel.id]?.length) {
    return whitelabelsAliases.value[whitelabel.id].filter(
      (whitelabel) => !!whitelabel.user
    );
  }

  whitelabelsAliases.value[whitelabel.id] =
    (await fetchAliases(whitelabel.id)) || [];
};

const removeWhitelabelBlueprint = () => {
  whitelabelsWithBlueprint.value =
    whitelabelsWithBlueprint.value?.filter(({ id }) => !!id) || null;
  activeWhitelabel.value = whitelabelsWithBlueprint.value?.[0] ?? null;
};

const handleAliasAction = async (id: IWhitelabel["id"]) =>
  Promise.all([fetchActiveWhitelabelAliases(id), getWhitelabels()]);

const { fetchWrapper: fetchAliases, loading: fetchAliasesLoading } =
  usePromiseWrapper(async (whitelabelId) => {
    if (!activeClient.value) {
      return;
    }
    const aliases = await clientsApiService.getWhitelabelAliases({
      clientId: activeClient.value.id,
      whitelabelId
    });
    return aliases?.filter((whitelabel) => !!whitelabel.user);
  });

const fetchActiveWhitelabelAliases = async (id: IWhitelabel["id"]) => {
  if (!id) {
    return;
  }
  whitelabelsAliases.value[id] = (await fetchAliases(id)) || [];
  activeWhitelabelAliases.value = whitelabelsAliases.value[id];
};

const { fetchWrapper: getWhitelabels, loading: fetchingWhitelabelsLoading } =
  usePromiseWrapper(async (keepBlueprint = true) => {
    await dispatch("clients/refreshActiveClient");
    if (!keepBlueprint) {
      whitelabelsWithBlueprint.value = storedWhitelabels.value;
      if (storedWhitelabels.value?.length) {
        activeWhitelabel.value =
          storedWhitelabels.value[storedWhitelabels.value.length - 1];
      }
      return;
    }
    whitelabelsWithBlueprint.value = [
      ...(whitelabelsWithBlueprint.value?.filter(({ id }) => !id) || []),
      ...(storedWhitelabels.value || [])
    ];
  });

const handleWhitelabelDelete = async () => {
  await getWhitelabels();

  if (storedWhitelabels.value?.length) {
    activeWhitelabel.value = storedWhitelabels.value[0];
    return;
  }
  if (whitelabelsWithBlueprint.value?.length) {
    activeWhitelabel.value = whitelabelsWithBlueprint.value[0];
    return;
  }

  activeWhitelabel.value = null;
};

watch(
  activeWhitelabel,
  async (newVal) => {
    if (!newVal?.id) {
      activeWhitelabelAliases.value = [];
      return;
    }

    const activeAliases = whitelabelsAliases.value[newVal.id];
    if (activeAliases?.length) {
      activeWhitelabelAliases.value = activeAliases;
      return;
    }

    await fetchActiveWhitelabelAliases(newVal.id);
  },
  { immediate: true }
);
</script>
