import { formatString } from "@/helpers/formatting";
import type { IUser } from "@/models/users";
import { axiosClient } from "@/services/client";
import endpoints from "@/services/endpoints";

class MyApiService {
  async getOrganizationUsers() {
    const response = await axiosClient.get<{ data: IUser[] }>(
      formatString(endpoints.MY.ORGANIZATION_USERS)
    );
    return response.data.data;
  }
}

const myApiService = new MyApiService();
export default myApiService;
