<template>
  <loader :isLoading="isLoading" />
  <div class="flex-flex-col m-6">
    <div class="mb-10">
      <lf-h2 class="mb-1-5">{{ $t("COMMON.COUNTRIES") }}</lf-h2>
      <span>{{ $t("ORGANIZATION.ADD_OR_REMOVE_COUNTRIES") }}</span>
    </div>
    <countries :client="client" />
  </div>
</template>
<script setup lang="ts">
import { useAuth } from "@/hooks/auth";
import type { IClient } from "@/models/clients";
import type { IRootState } from "@/models/state";
import { computed } from "vue";
import { useStore } from "vuex";
import Countries from "./components/Countries.vue";

const { isClientAdmin } = useAuth();
const { getters } = useStore<IRootState>();

const client = computed<IClient>(
  () =>
    (isClientAdmin && getters["auth/authClientSettings"]) ||
    getters["clients/active"]
);

const isLoading = computed(() => !client.value.id);
</script>
