<template>
  <div class="flex w-full justify-between space-x-8 max-w-4xl">
    <loader :is-loading="isSubmitting || isLoading" />
    <lf-card class="py-6 w-[700px]">
      <template v-slot>
        <form @submit.prevent="onSubmit">
          <div class="mt-5 space-y-5">
            <lf-h3 class="mb-1">
              {{ $t("ORGANIZATION.API_CONFIG.DEALSTAGE_WEBHOOK") }}
            </lf-h3>
            <span>
              {{ $t("ORGANIZATION.API_CONFIG.BODY_DESCRIPTION") }}
            </span>
            <lf-input
              :placeholder="$t('ORGANIZATION.API_CONFIG.DEALSTAGE_PLACEHOLDER')"
              :value="client.offer_webhook"
              name="offer_webhook"
              type="text"
              class="pt-3 mb-4"
              :disabled="isFundingAdvisor || isClientFundingAdvisor"
            />
            <div data-testid="appProgressNotifications">
              <lf-switch
                v-model="progressNotifications"
                name="application-progress-notifications"
                class="py-6 border-b border-t border-gray-200"
                :disabled="!enableEditing"
                data-cy="appProgressSwitch"
                @change="toggleNotifications(APP_PROGRESS)"
              >
                <lf-h4>
                  {{
                    $t("NOTIFICATIONS.PROGRESS_NOTIFICATIONS", {
                      company: client.name
                    })
                  }}
                </lf-h4>
              </lf-switch>
              <div
                v-if="progressNotifications"
                class="grid grid-cols-3 bg-disabled px-6 py-2 -mx-6 border-t border-b shadow-inner"
              >
                <lf-checkbox
                  v-for="notification in progressNotificationTypes"
                  :key="notification.id"
                  v-model="selectedProgressNotifications"
                  class="my-2 mx-1 items-center"
                  :name="notification.name"
                  :value="notification.id"
                  :disabled="!enableEditing"
                  :test-id="`checkbox-${notification.name}`"
                >
                  {{ notification.name }}
                </lf-checkbox>
                <lf-checkbox
                  v-for="notification in offerStatusNotificationTypes"
                  :key="notification.id"
                  v-model="selectedOfferStatusNotifications"
                  class="my-2 mx-1 items-center"
                  :name="notification.name"
                  :value="notification.id"
                  :disabled="!enableEditing"
                  :test-id="`checkbox-${notification.name}`"
                >
                  {{ notification.name }}
                </lf-checkbox>
                <lf-checkbox
                  v-for="notification in plaidNotificationTypes"
                  :key="notification.id"
                  v-model="selectedPlaidNotifications"
                  class="my-2 mx-1 items-center"
                  :name="notification.name"
                  :value="notification.id"
                  :disabled="!enableEditing"
                  :test-id="`checkbox-${notification.name}`"
                >
                  {{ notification.name }}
                </lf-checkbox>
                <lf-checkbox
                  v-for="notification in codatConnectionNotificationTypes"
                  :key="notification.id"
                  v-model="selectedCodatConnectionNotifications"
                  class="my-2 mx-1 items-center"
                  :name="notification.name"
                  :value="notification.id"
                  :disabled="!enableEditing"
                  :test-id="`checkbox-${notification.name}`"
                >
                  {{ notification.name }}
                </lf-checkbox>
                <lf-checkbox
                  v-for="notification in docuSignNotificationTypes"
                  :key="notification.id"
                  v-model="selectedDocuSignNotifications"
                  class="my-2 mx-1 items-center"
                  :name="notification.name"
                  :value="notification.id"
                  :disabled="!enableEditing"
                  :test-id="`checkbox-${notification.name}`"
                >
                  {{ notification.name }}
                </lf-checkbox>
                <lf-checkbox
                  v-for="notification in documentUploadNotificationTypes"
                  :key="notification.id"
                  v-model="selectedDocumentUploadNotifications"
                  class="my-2 mx-1 items-center"
                  :name="notification.name"
                  :value="notification.id"
                  :disabled="!enableEditing"
                  :test-id="`checkbox-${notification.name}`"
                  @click="
                    handleDocumentUploadNotifications($event, notification.id)
                  "
                >
                  {{ notification.name }}
                </lf-checkbox>
              </div>
            </div>
            <div
              v-if="canManageDataOrchestration"
              data-testid="doNotifications"
            >
              <lf-switch
                v-model="dataOrchestrationNotifications"
                name="data-orchestration-notifications"
                class="pb-6 border-b border-gray-200"
                :disabled="!enableEditing"
                data-testid="doSwitch"
                @change="toggleNotifications(DATA_ORCHESTRATION)"
              >
                <lf-h4>
                  {{
                    $t("NOTIFICATIONS.DO_STATUS_NOTIFICATIONS", {
                      company: client.name
                    })
                  }}
                </lf-h4>
              </lf-switch>
              <div
                v-if="dataOrchestrationNotifications"
                class="grid grid-cols-4 bg-disabled px-6 py-2 -mx-6 border-t border-b shadow-inner"
              >
                <lf-checkbox
                  v-for="notification in dataOrchestrationNotificationTypes"
                  :key="notification.id"
                  v-model="selectedDataOrchestrationNotifications"
                  class="my-2"
                  :name="notification.name"
                  :value="notification.id"
                  :disabled="!enableEditing"
                  :test-id="`checkbox-${notification.name}`"
                >
                  {{ notification.name }}
                </lf-checkbox>
              </div>
            </div>
            <lf-h3 class="mb-1">
              {{ $t("ORGANIZATION.API_CONFIG.CREDITDATA_WEBHOOK") }}
            </lf-h3>
            <span>
              {{ $t("ORGANIZATION.API_CONFIG.BODY_DESCRIPTION") }}
            </span>
            <lf-input
              :placeholder="
                $t('ORGANIZATION.API_CONFIG.CREDITDATA_PLACEHOLDER')
              "
              :value="client.credit_webhook"
              name="credit_webhook"
              type="text"
              :disabled="isFundingAdvisor || isClientFundingAdvisor"
            />
            <div>
              <lf-h3 class="mb-1">
                {{ $t("ORGANIZATION.API_CONFIG.DOCUSIGN_WEBHOOK") }}
              </lf-h3>
              <span>
                {{ $t("ORGANIZATION.API_CONFIG.BODY_DESCRIPTION") }}
              </span>
              <div class="mt-5">
                <lf-input
                  name="docusign_webhook"
                  type="text"
                  :placeholder="
                    $t('ORGANIZATION.API_CONFIG.DOCUSIGN_PLACEHOLDER')
                  "
                  :value="client.docusign_webhook"
                  :disabled="isFundingAdvisor || isClientFundingAdvisor"
                />
              </div>
            </div>
            <div
              class="flex justify-end items-center min-w-full border-t pt-6 pr-5"
            >
              <primary-button
                type="submit"
                :disabled="isSubmitting"
                data-testid="submitBtn"
              >
                {{ $t("ORGANIZATION.API_CONFIG.SAVE_CHANGES") }}
              </primary-button>
            </div>
          </div>
        </form>
      </template>
    </lf-card>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";
import { useAuth } from "@/hooks/auth";
import type { IClient } from "@/models/clients";
import type { INotification, INotificationTypes } from "@/models/options";

import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import { useI18n } from "vue-i18n";

const APP_PROGRESS = "app_progress" as const;
const DATA_ORCHESTRATION = "data_orchestration" as const;

const ANY_DOCUMENT_UPLOAD_HOOK_ID = 34;

const route = useRoute();
const { getters, dispatch, commit } = useStore();
const {
  isAdmin,
  isFundingAdvisor,
  isClientFundingAdvisor,
  isClientAdmin,
  isClient
} = useAuth();
const { t } = useI18n();

const enableEditing = computed(() => isAdmin || isClientAdmin);

const isLoading = ref(false);
const progressNotifications = ref(false);
const docuSignNotifications = ref(false);
const selectedProgressNotifications = ref<number[]>([]);
const selectedDocuSignNotifications = ref<number[]>([]);
const selectedPlaidNotifications = ref<number[]>([]);
const selectedDocumentUploadNotifications = ref<number[]>([]);
const dataOrchestrationNotifications = ref(false);
const selectedDataOrchestrationNotifications = ref<number[]>([]);
const selectedOfferStatusNotifications = ref<number[]>([]);
const selectedCodatConnectionNotifications = ref<number[]>([]);

const client = computed<IClient>(() => {
  const getterName = isClientAdmin
    ? "auth/authClientSettings"
    : "clients/active";
  return getters[getterName];
});

const canManageDataOrchestration = computed(
  () => client.value.can_manage_data_orchestration
);
const progressNotificationTypes = computed(
  () => (getters["options/notificationTypes"] as INotificationTypes)[1]
);

const plaidNotificationTypes = computed(
  () => (getters["options/notificationTypes"] as INotificationTypes)[5]
);

const dataOrchestrationNotificationTypes = computed(
  () => (getters["options/notificationTypes"] as INotificationTypes)[4]
);

const docuSignNotificationTypes = computed(
  () => (getters["options/notificationTypes"] as INotificationTypes)[6]
);

const documentUploadNotificationTypes = computed(
  () => (getters["options/notificationTypes"] as INotificationTypes)[7]
);

const offerStatusNotificationTypes = computed(
  () => (getters["options/notificationTypes"] as INotificationTypes)[8]
);

const codatConnectionNotificationTypes = computed(
  () => (getters["options/notificationTypes"] as INotificationTypes)[9]
);

const initialValues = reactive({
  offer_webhook: client.value.offer_webhook ?? "",
  credit_webhook: client.value.credit_webhook ?? "",
  docusign_webhook: client.value.docusign_webhook ?? ""
});

const { handleSubmit, isSubmitting } = useForm({
  initialValues
});
const onSubmit = handleSubmit(
  async (values: Partial<typeof initialValues>, actions) => {
    const data = {
      credit_webhook: values.credit_webhook,
      offer_webhook: values.offer_webhook,
      docusign_webhook: values.docusign_webhook
    };

    try {
      const isClientUpdated = await dispatchAction(
        { data, id: client.value.id, auth: isClientAdmin },
        actions,
        "clients/updateClient"
      );

      if (!isClientUpdated) {
        return;
      }
    } catch {
      return;
    }

    const notifications: {
      2: number[];
      3: number[];
      4?: number[];
      5?: number[];
      6?: number[];
      7?: number[];
      8?: number[];
      9?: number[];
    } = {
      2: (client.value.notifications || [])
        .filter((n) => n.group === 2)
        .map((n) => n.id),
      3: selectedProgressNotifications.value,
      4: selectedDataOrchestrationNotifications.value,
      5: selectedPlaidNotifications.value,
      6: selectedDocuSignNotifications.value,
      7: selectedDocumentUploadNotifications.value,
      8: selectedOfferStatusNotifications.value,
      9: selectedCodatConnectionNotifications.value
    };

    if (!canManageDataOrchestration.value) {
      delete notifications[4];
    }

    if (enableEditing.value) {
      try {
        await dispatch("clients/updateClientNotifications", {
          clientId: client.value.id,
          data: { notifications },
          auth: isClientAdmin
        });
      } catch (error) {
        commit("setGlobalMessage", {
          title: t("COMMON.ERROR_OCCURRED"),
          type: "error"
        });
      }
    }
  }
);

const assignAllNotifications = (notTypes: INotification[]) =>
  (notTypes || []).map((n) => n.id);

const toggleNotifications = (
  type: typeof APP_PROGRESS | typeof DATA_ORCHESTRATION
) => {
  if (type === APP_PROGRESS) {
    if (!progressNotifications.value) {
      selectedProgressNotifications.value = [];
      selectedPlaidNotifications.value = [];
      selectedOfferStatusNotifications.value = [];
      selectedCodatConnectionNotifications.value = [];
      return;
    }
    selectedProgressNotifications.value = assignAllNotifications(
      progressNotificationTypes.value
    );
    selectedDocuSignNotifications.value = assignAllNotifications(
      docuSignNotificationTypes.value
    );
    selectedPlaidNotifications.value = assignAllNotifications(
      plaidNotificationTypes.value
    );
    selectedOfferStatusNotifications.value = assignAllNotifications(
      offerStatusNotificationTypes.value
    );
    selectedCodatConnectionNotifications.value = assignAllNotifications(
      codatConnectionNotificationTypes.value
    );
    selectedDocumentUploadNotifications.value = [ANY_DOCUMENT_UPLOAD_HOOK_ID];
    return;
  }
  if (type === DATA_ORCHESTRATION) {
    if (!dataOrchestrationNotifications.value) {
      selectedDataOrchestrationNotifications.value = [];
      return;
    }
    selectedDataOrchestrationNotifications.value = assignAllNotifications(
      dataOrchestrationNotificationTypes.value
    );
    return;
  }
};
const initValue = (groupId: keyof INotificationTypes) =>
  (client.value.notifications || []).reduce((acc: number[], notification) => {
    if (notification.group === groupId) {
      acc.push(notification.id);
    }
    return acc;
  }, []);

const handleDocumentUploadNotifications = (e: Event, hookId: number) => {
  if ((e.target as HTMLInputElement)?.checked) {
    selectedDocumentUploadNotifications.value = [hookId];
  }
};

onMounted(async () => {
  isLoading.value = true;
  if (client.value.notifications) {
    selectedProgressNotifications.value = initValue(3);
    selectedDataOrchestrationNotifications.value = initValue(4);
    selectedPlaidNotifications.value = initValue(5);
    selectedDocuSignNotifications.value = initValue(6);
    selectedDocumentUploadNotifications.value = initValue(7);
    selectedOfferStatusNotifications.value = initValue(8);
    selectedCodatConnectionNotifications.value = initValue(9);

    progressNotifications.value = !![
      ...selectedProgressNotifications.value,
      ...selectedDocumentUploadNotifications.value,
      ...selectedCodatConnectionNotifications.value
    ].length;
  }
  if (selectedDataOrchestrationNotifications.value?.length) {
    dataOrchestrationNotifications.value = true;
  }
  if (selectedPlaidNotifications.value?.length) {
    docuSignNotifications.value = true;
  }
  const actionName = isClient
    ? "auth/getAuthClientIntegration"
    : "clients/getActiveClientIntegration";

  try {
    await dispatch(actionName, route.params.id);
  } finally {
    isLoading.value = false;
  }
});
</script>
